import React from 'react';
import { useEnv } from './core/useEnv';

export const EnvWrapper = ({children}) => {
    const {initialized} = useEnv();

    return (
        <>
            {initialized && children}
        </>
    )
};

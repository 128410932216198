import React from 'react';
import {Form, Modal, ModalBody, Button, ModalFooter, Alert} from "@george-labs.com/design-system";

const MessengerModal = (props) => {

    const {onToggle, isOpen, onSubmit, submitBtnText, closeBtnText = 'Zavřít', header, body, closeAfterSubmit = true, footer, size, footerClassName, processing, processingText, error, errorText = 'Během zpracování došlo k chybě'} = props;

    const submitForm = (event) => {
        onSubmit().then(() => {
            if (closeAfterSubmit) {
                onToggle();
            }
        });
        event.preventDefault();
    };

    const renderError = () => {
        if (error) {
            return (
                <Alert variant={Alert.VARIANT.ERROR}>
                    {errorText}
                </Alert>
            )
        }
    };

    const getFooter = () => {
        if (footer) {
            return (
                <ModalFooter className={footerClassName ? footerClassName : ""}>
                    {footer}
                </ModalFooter>
            )
        }
        return (
            <ModalFooter>
                {onToggle ?
                    <Button variant={Button.VARIANT.SECONDARY} onClick={onToggle}>
                        {closeBtnText}
                    </Button> : null}
                {onSubmit ?
                    <Button variant={Button.VARIANT.PRIMARY} onClick={submitForm}>
                        {submitBtnText}
                    </Button> : null}
            </ModalFooter>
        )
    };

    return (
        <Modal isOpen={isOpen} toggle={onToggle} size={size} title={header} isLoading={processing} loadingText={processingText}>
            <Form>
                    <ModalBody>
                        {body}
                        {renderError()}
                    </ModalBody>
                    {getFooter()}
            </Form>
        </Modal>
    )
};

export default MessengerModal;
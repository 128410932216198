import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "reactstrap";
import SectionRow from "./sectionRow";
import {getUserFullName} from "../actions/humanTaskActions";

const ClientDetail = ({className, clientData}) => {

    const cluid = useSelector(state => state?.messenger?.cluid);
    const {personalId, address, primaryPhoneNumber, primaryEmail, channels, privateBankerUsername} = clientData;
    const actualOwner = useSelector(state => state.humanTask.humanTask.actualOwner || "");
    const actualOwnerFullName = useSelector(state => state.humanTask.actualOwnerFullName || "");
    const [bankerFullName, setBankerFullName] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if (privateBankerUsername && (actualOwner?.toLowerCase() !== privateBankerUsername.toLowerCase() || !actualOwnerFullName)) {
            dispatch(getUserFullName(privateBankerUsername, false)).then((result) => setBankerFullName(result.fullName));
        } else if (privateBankerUsername && (actualOwner?.toLowerCase() === privateBankerUsername.toLowerCase())) { //pokud je actuallOwner zároveň bankéř a jeho jméne je načteno, nemusíme volat
            setBankerFullName(actualOwnerFullName);
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientData, actualOwnerFullName]);

    const viewSign = [];
    if (channels?.VIEW?.channel) {
        viewSign.push("nahlížet");
    }
    if (channels?.SIGN?.channel) {
        viewSign.push("podepisovat");
    }

    const format = (value, pattern) => {
        if (!value){
            return "";
        }
        var i = 0,
            phone = value.toString();
        return pattern.replace(/#/g, _ => phone[i++]);
    };

    return (
        <React.Fragment>
            <Row className={className}>
                <Col md={12}>
                    <SectionRow text={personalId} icon={"calendar"}/>
                    <SectionRow text={address} icon={"location"}/>
                    <SectionRow text={format(primaryPhoneNumber, '### ### ###')} icon={"phone-hotline"} active={true}/>
                    <SectionRow text={primaryEmail} icon={"mail"}/>
                    <hr className="mt-2 mb-3"/>
                    <SectionRow text={cluid} icon={"s-id-app"} copy={true}/>
                    <SectionRow text={(viewSign.length === 0) ? "V George nemůže nahlížet ani podepisovat dokumenty" : "V George může " + viewSign.join('/') + " dokumenty"} icon={"george"}/>
                    <SectionRow text={bankerFullName} emptyText='Klient nemá bankéře' icon={"addressbook"}/>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default ClientDetail;
import { useEffect, useState } from "react";
import { env } from "../configuration/config";

export function useEnv() {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetch("config/env.json");
            const json = await data.json();
            env.set(json);
            setInitialized(true);
        };
        fetchData()
            .catch(console.error);
        return () => {
            setInitialized(false)
        }
    }, []);

    return {initialized};
}

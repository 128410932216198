/**
 * This class contains all the logic assosiated with grouping of messages. 
 * Friday afternoon, demo in few hours, dont judge me too harshly.
 */

import {generateFormatDate, translateDay} from "../../../core/utils";

const GROUP_DIFFERENCE = 900000; // 15m in ms

const isSameDay = (d1, d2) => {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

const evaluateDateFormat = (groupDate) => {
    // 1) Today
    const today = new Date();
    if (isSameDay(new Date(groupDate), today)) {
        return "Dnes";
    }

    // 2) Yesterday
    const yesterday = today.setDate(today.getDate() - 1);
    if (isSameDay(new Date(groupDate), new Date(yesterday))){
        return "Včera";
    }

    // 3) Days of week: Pondělí, úterý apod: mimo MVP

    // 4) Default as a date
    const result = translateDay(generateFormatDate(groupDate, "dd DD.MM.YYYY"));
    return result;
}

const evaluateFirstGroupOfTheDay = (messageGroups, newGroup) => {
    // 1) It is first message group
    if (messageGroups.length < 1){
        newGroup = Object.assign(newGroup, {groupDate: evaluateDateFormat(newGroup.messages[0].createdAt)});
        return newGroup;
    }

    // 2. It is existing message group
    const newGroupCreatedAt = newGroup.messages[0].createdAt;
    const newGroupDate = new Date(newGroupCreatedAt);

    const lastGroup = messageGroups[messageGroups.length-1];
    const lastGroupCreatedAt = lastGroup.messages[0].createdAt;
    const lastGroupDate = new Date(lastGroupCreatedAt);

    if (!isSameDay(newGroupDate, lastGroupDate)){
        newGroup = Object.assign(newGroup, {groupDate: evaluateDateFormat(newGroup.messages[0].createdAt)});
    }
    return newGroup;
}

const insertItemToGroups = (item, messageGroups) => {

    // 1) Check, if I should push into existing group
    if (messageGroups && messageGroups.length >= 1){
        const lastGroup = messageGroups[messageGroups.length-1];
        const lastMessage = lastGroup.messages[lastGroup.messages.length-1];
        //There is no longer validation for belongsToSameUser functionality
        if (item.createdAt - lastMessage.createdAt <= GROUP_DIFFERENCE){
            lastGroup.messages.push(item);
            return messageGroups;
        }
    }

    // 2) Create a new message group.
    const groupMessages = [];
    groupMessages.push(item);
    let newGroup = Object.assign({}, {messages: groupMessages});
    // Check if it is first group of the day
    newGroup = evaluateFirstGroupOfTheDay(messageGroups, newGroup);

    messageGroups.push(newGroup);
    return messageGroups;
}

export const createMessageGroups = (sortedItems) => {
    let messageGroups = [];
    sortedItems.forEach(item => {
        messageGroups = insertItemToGroups(item, messageGroups);
    });
    return messageGroups;
}
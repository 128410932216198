import React, {useEffect, useState} from "react";
import {Icon, Paragraph} from "@george-labs.com/design-system";
import {useDispatch, useSelector} from "react-redux";
import {useResizeDetector} from "react-resize-detector";
import {menuToggled} from "../../actions/layoutAction";
import {findConfiguration} from "./utils/actionStripeConfig";
import ActionStripeState from "./actionStripeBadge";
import { Col } from "reactstrap";

/**
 * Component with kick-ass name and kicked-ass content.
 */
const ActionStripe = () => {
    const { ref } = useResizeDetector();
    const thread = useSelector(state => state?.messenger?.thread);
    const client = useSelector(state => state?.messenger?.client);
    const user = useSelector(state => state?.user?.identity);
    const humanTask = useSelector(state => state?.humanTask?.humanTask);
    const messageCount = useSelector(state => state?.messenger?.messageCount);
    const subject = useSelector(state => state.messenger?.mergedSubject || "");
    const open = useSelector(state => state.layout.menuOpen);
    const [config, setConfig] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        let config = findConfiguration(humanTask, thread, client, user);
        setConfig(config)
     // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [humanTask?.actualOwner, humanTask?.taskStatus, messageCount, client, thread?.deletedAt]);

    return (
        <>
            <div className='row py-2' ref={ref}>
                <Col className='text-left col-auto'>
                    <Paragraph>
                        <ActionStripeState config={config} inMenuSection={false}/>
                    </Paragraph>
                </Col>
                <Col className='text-center'>
                    <Paragraph>
                        <span className='font-weight-bold'>{subject}</span>
                    </Paragraph>
                </Col>
                <Col className='col-auto text-right'>
                    <Paragraph className='selectable' onClick={() => dispatch(menuToggled(!open))}>
                        <Icon color={Icon.COLOR.BLUE_300} icon={Icon.ICON.MENU} size={Icon.SIZE.SIZE_24}/>
                        <span className='text-primary font-weight-bold d-none d-sm-inline-block'>
                            Akce
                        </span>
                    </Paragraph>
                </Col>
            </div>
        </>
    );
};

export default ActionStripe;

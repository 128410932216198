class GlobalConfig {

    config = null;

    getInstance() {
        if (GlobalConfig.instance === undefined) {
            GlobalConfig.instance = new GlobalConfig();
        }
        return GlobalConfig.instance;
    }

    get() {
        if (!!this.config) {
            console.log("Global config has not been defined yet.");
        } else {
            return this.config;
        }
    }

    set(value) {
        if (!this.config) {
            this.config = value;
        }
    }
}

export const env = new GlobalConfig().getInstance();

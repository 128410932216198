import React, {useEffect, useState} from 'react';
import {stringify} from 'query-string';
import {ALERT_INFO} from './core/const';
import {useDispatch, useSelector} from "react-redux";
import {
    adfsRequestFailed,
    authLoaded, expiringAccess, expiringAccessNoted,
    getAccessToken,
    refreshAccessToken
} from "./actions/authActions";
import {extractParamFromURL, registerInterceptor, removeParamsFromUrl} from "./core/utils";
import {fireLogout} from "./actions/userActions";
import DialogInfo from './core/dialogInfo';
import {fireShowCountdownAlert} from "./actions/alertActions";
import {Spinner} from "@george-labs.com/design-system";
import Resource from "./core/serverResource";

const AUTH_PARAMS = {
    response_type: "code",
    client_id: "urn:csas:apps:mit",
    scope: "openid",
    state: 12345,
    redirect_uri: window.location.href
};

export const AppWithAuth = (props) => {

    const ENV_PARAM = Resource.getEnv();
    const ADFS_BASE_URL = Resource.getAdfsUrl();

    const accessToken = useSelector(state => state.authorization.accessToken);
    const expireTime = useSelector(state => state.authorization.expireTime);
    const refreshExpireTime = useSelector(state => state.authorization.refreshExpireTime);

    const adfsAuthFailed = useSelector(state => state.authorization.adfsAuthFailed);
    const adfsAccessExpiring = useSelector(state => state.authorization.adfsAccessExpiring);
    const adfsAccessExpiringNoted = useSelector(state => state.authorization.adfsAccessExpiringNoted);
    const adfsAccessTokenFailed = useSelector(state => state.authorization.adfsAccessTokenFailed);
    const adfsAccessRefreshingFailed = useSelector(state => state.authorization.adfsAccessRefreshingFailed);
    const adfsAccessTokenLoading = useSelector(state => state.authorization.adfsAccessTokenLoading);

    const [unsupported, setUnsupported] = useState(false);

    const dispatch = useDispatch();
    const EXPIRATION_THRESHOLD_IN_SECONDS = 10;
    const REFRESH_EXPIRATION_THRESHOLD_IN_SECONDS = 300;

    const isUnsupportedBrowser = () => {
        return false;
    };

    const getAccessData = () => {
        const expireTime = getSessionParam('expire_time');
        const refresh_expire_time = getSessionParam('refresh_expire_time');
        return {
            code: getSessionParam('code'),
            state: getSessionParam('state'),
            access_token: getSessionParam('access_token'),
            refresh_token: getSessionParam('refresh_token'),
            refresh_token_expires_in: getSessionParam('refresh_token_expires_in'),
            expires_in: getSessionParam('expires_in'),
            expire_time: expireTime ? new Date(expireTime) : null,
            refresh_expire_time: refresh_expire_time ? new Date(refresh_expire_time) : null
        }
    };

    const isAccessReady = (accessData) => {
        return accessData.access_token && accessData.expire_time && accessData.expire_time > new Date();
    };

    const isAccessExpired = (accessData) => {
        return accessData.expire_time && accessData.expire_time <= new Date();
    };

    const isAccessNeeded = (accessData) => {
        return !accessData.code && !accessData.access_token;
    };

    const getAuthCodeParams = () => {
        return {
            code: getSessionParam('code'),
            state: getSessionParam('state'),
        }
    };

    const setAuthCodeParams = () => {
        setSessionParam(extractParamFromURL('code'), 'code');
        setSessionParam(extractParamFromURL('state'), 'state');
    };

    const handleExpiringToken = () => {
        const data = getAccessData();
        if (data.refresh_token) {
            dispatch(refreshAccessToken(data, AUTH_PARAMS.client_id, AUTH_PARAMS.scope)).then(data => {
                setSessionParam(data.access_token, 'access_token');
                setSessionParam(data.id_token, 'id_token');
                setSessionParam(getExpirationDate(data.expires_in), 'expire_time');
                registerInterceptor(data.access_token)
            }).catch(err => {
                console.error(err)
            })
        }
    };

    const handleExpiringAccess = () => {
        dispatch(expiringAccess());
        dispatch(fireShowCountdownAlert("Aplikace Vás odhlásí. Můžete si ale obnovit stránku a předejít odhlášení.", ALERT_INFO, expireTime, retryAuth, "Obnovit stránku"))
    };

    const checkAccessToken = (expireTime, refreshExpireTime) => {
        if (expireTime && refreshExpireTime) {
            const accessTimeout = expireTime.getTime() - new Date().getTime() - EXPIRATION_THRESHOLD_IN_SECONDS * 1000;
            const refreshTimeout = refreshExpireTime.getTime() - new Date().getTime() - EXPIRATION_THRESHOLD_IN_SECONDS * 1000;
            setTimeout(() => handleExpiringToken(), accessTimeout);
            if (accessTimeout > refreshTimeout) {
                setTimeout(() => handleExpiringAccess(), accessTimeout - REFRESH_EXPIRATION_THRESHOLD_IN_SECONDS * 1000)
            }
        }
    };

    const setAuthAccessParams = (data) => {
        setSessionParam(data.access_token, 'access_token');
        setSessionParam(data.token_type, 'token_type');
        setSessionParam(data.expires_in, 'expires_in');
        setSessionParam(data.refresh_token_expires_in, 'refresh_token_expires_in');
        setSessionParam(data.refresh_token, 'refresh_token');
        setSessionParam(data.id_token, 'id_token');
        setSessionParam(getExpirationDate(data.expires_in), 'expire_time');
        setSessionParam(getExpirationDate(data.refresh_token_expires_in), 'refresh_expire_time');
    };

    const getExpirationDate = (expiresIn) => {
        let futureInMillis = new Date().getTime() + expiresIn * 1000;
        return new Date(futureInMillis);
    };

    const setSessionParam = (data, id) => {
        if (data) {
            window.sessionStorage.setItem(ENV_PARAM + "-" + id, data);
        }
    };

    const getSessionParam = (id) => {
        return window.sessionStorage.getItem(ENV_PARAM + "-" + id);
    };

    const doRedirect = (url) => {
        window.location.replace(url);
    };

    const isQueryParamsAuthValid = () => {
        return extractParamFromURL('code');
    };

    const isURLResponseValid = () => {
        return !extractParamFromURL('error');
    };

    useEffect(() => {
        if (accessToken) {
            checkAccessToken(expireTime, refreshExpireTime);
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expireTime, refreshExpireTime, accessToken]);

    useEffect(() => {
        //0] First of all check browser
        if (isUnsupportedBrowser()) {
            setUnsupported(true);
            return;
        }

        let accessData = getAccessData();
        //1] If access expired, clear cache and proceed
        if (isAccessExpired(accessData)) {
            window.sessionStorage.clear();
            accessData = getAccessData();
        }

        //2] Check if cache isnt already initialized with access data (user refreshed page)
        if (isAccessReady(accessData)) {
            dispatch(authLoaded(accessData));
            registerInterceptor(accessData.access_token);
            return;
        }

        //3] Check possible redirect from ADFS for errors
        if (!isURLResponseValid()) {
            dispatch(adfsRequestFailed());
            return;
        }

        //4] Check if access is needed - no code, and no access token
        if (isAccessNeeded(accessData)) {
            //4.1] Redirect to adfs, we need the code
            if (!isQueryParamsAuthValid()) {
                doRedirect(ADFS_BASE_URL + "/adfs/oauth2/authorize?" + stringify(AUTH_PARAMS));
                //4.1] We have the code, save code/state to session storage
            } else {
                setAuthCodeParams();
                doRedirect(removeParamsFromUrl(['code', 'state', 'client-request-id']));
            }
            //5] We have the code, but no access token. Get it
        } else {
            //5.1] Load params from session
            const auth = getAuthCodeParams();
            //5.2] Call token enpoint
            dispatch(getAccessToken(auth.code, AUTH_PARAMS.client_id, AUTH_PARAMS.scope, AUTH_PARAMS.redirect_uri)).then(data => {
                setAuthAccessParams(data);
            }).catch((err) => {
                // window.sessionStorage.clear()
                console.error(err)
            });
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const retryAuth = () => {
        dispatch(fireLogout())
    };

    const handleCloseNotify = () => {
        dispatch(expiringAccessNoted());
    };

    const getUnsupportedBody = () => {
        return (
            <>
                <span className="d-block">
                       Dobrý den. Omlouváme se za způsobené komplikace, ale Internet Explorer je aktuálně jediným nepodporovaným prohlížečem.
                       Pro práci v aplikaci Zprávy s Bankéřem použijte prosím například prohlížeče Chrome, nebo Edge.
                </span>
                <span className="d-block mt-2">
                    Níže uvedený odkaz prosím zkopírujte do jakéhokoli z těchto prohlížečů.
                </span>
                <span className="d-block mt-2">
                    Děkujeme za pochopení.
                </span>
                <span className="d-block mt-4 border border-info bg-light p-3">
                    {AUTH_PARAMS.redirect_uri}
                </span>
            </>
        )
    };

    const getAuthInProgressBody = () => {
        return (
            <>
                <span className="d-block my-2 text-center">
                    <Spinner/>
                </span>
                <span className="d-block text-center">
                       Zařizujeme přístup do aplikace, strpení prosím.
                </span>
            </>
        )
    };

    return (
        <>
            <DialogInfo color="warning" isOpen={adfsAccessRefreshingFailed} onSubmit={retryAuth}
                        closeable={false}
                        header="Váš přístup do aplikace vypršel"
                        info="Z bezpečnostních důvodů Vás bohužel nemůžeme přihlásit napořád, a nyní si budete muset obnovit stránku. Omlouváme se za komplikace."
                        submitLabel="Obnovit stránku"/>

            <DialogInfo color="danger" isOpen={adfsAccessTokenFailed} onSubmit={retryAuth}
                        closeable={false}
                        header="Je nám líto, ale přihlášení selhalo"
                        info="Během vašeho přihlášení došlo k problému. Můžete to zkusit znovu obnovením stránky. Pokud problém přetrvává, kontaktujte prosím podporu."
                        submitLabel="Obnovit stránku"/>

            <DialogInfo color="danger" isOpen={adfsAuthFailed} onSubmit={retryAuth}
                        closeable={false}
                        header="Je nám líto, ale zřizování přístupu selhalo"
                        info="Během zřizování přístupu došlo k problému. Můžete to zkusit znovu obnovením stránky. Pokud problém přetrvává, kontaktujte prosím podporu."
                        submitLabel="Obnovit stránku"/>

            <DialogInfo color="info" isOpen={adfsAccessTokenLoading}
                        closeable={false}
                        header="Probíhá přihlašování"
                        info={getAuthInProgressBody()}/>

            <DialogInfo color="warning" isOpen={adfsAccessExpiring && !adfsAccessExpiringNoted}
                        closeable={false}
                        onSubmit={retryAuth} onToggle={handleCloseNotify}
                        header="Vaše přihlášení za chvíli vyprší"
                        info="Nezoufejte ale, ještě několik minut můžete pokračovat v práci. Pokud ale momentálně nemáte rozpracované zadávání žádného požadavku,
                            bude lepší pokud si prodloužíte přihlášení hned."
                        toggleLabel="Pokračovat v práci"
                        submitLabel="Prodloužit přihlášení"/>

            <DialogInfo color="info" isOpen={unsupported}
                        closeable={false}
                        header="Nepodporovaný prohlížeč"
                        info={getUnsupportedBody()}/>

            {accessToken ? props.children : null}
        </>
    )

};

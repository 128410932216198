import {
    SET_CIDLA,
    SET_CLUID,
    SET_THREAD,
    USER_LOADING,
    UPDATE_HUMAN_TASK,
    CLIENT_LOADED,
    SET_MESSAGE_COUNT,
    SET_ADD_MESSAGE,
    DOCUMENT_UPLOADING,
    DOCUMENT_UPLOADING_ERR,
    SET_USERS_PHOTOS,
    SET_FUTURE_THREAD_SUBJECT,
    FILE_SOURCE_LOCAL,
    FILE_SOURCE_ESPIS,
    ADD_MESSAGE,
    MESSAGE_UPLOADED,
    MESSAGE_UPLOAD_FAIL,
    ATTACHMENT_UPLOADED,
    ATTACHMENT_UPLOAD_FAIL,
    ATTACHMENT_UPLOADING,
    MESSAGE_UPLOADING,
    CLIENT_LOADING_FAILED,
    CLIENT_LOADING,
    THREAD_LOADING,
    THREAD_LOADING_FAILED,
    SET_THREAD_HISTORY,
    SET_NEW_NOTE,
    ALERT_DANGER,
    ALERT_SUCCESS,
    ALERT_WARNING,
    THREAD_HISTORY_LOADING,
    THREAD_HISTORY_LOADING_FAILED,
    INITIAL_LOADING,
    INITIAL_LOADING_DONE,
    INITIAL_LOADING_FAILED,
    NEW_NOTE_LOADING,
    NEW_NOTE_LOADING_FAILED,
    TAKE_READY_STATES, CLIENT_DETAIL_OPEN
} from "../core/const";
import Resource from '../core/serverResource';
import {generateUUID} from "../core/utils";
import {fireShowAlert} from "./alertActions";
import {fireGetLoggedUser} from "./userActions";
import {getHumanTask, takeHumanTask} from "./humanTaskActions";
import {
    canSolve, isActualOwner,
    isTaskInStatus
} from "../comp/stripe/utils/actionStripeConfig";
import {fireLoadEnvironment, fireLoadMitModuleDispatch} from "./environmentActions";

export const setUserLoading = (loading) => (
    {type: USER_LOADING, loading}
);

export const initialLoading = () => (
    {type: INITIAL_LOADING}
);

export const initialLoadingDone = () => (
    {type: INITIAL_LOADING_DONE}
);

export const initialLoadingFailed = () => (
    {type: INITIAL_LOADING_FAILED}
);

export const setThread = (thread) => (
    {type: SET_THREAD, thread}
);

export const threadLoading = (silent) => (
    {type: THREAD_LOADING, silent}
);

export const threadLoadingFailed = (err) => (
    {type: THREAD_LOADING_FAILED, err}
);

export const threadHistoryLoading = () => (
    {type: THREAD_HISTORY_LOADING}
);

export const setThreadHistory = (history) => (
    {type: SET_THREAD_HISTORY, history}
);

export const threadHistoryLoadingFailed = (err) => (
    {type: THREAD_HISTORY_LOADING_FAILED, err}
);

export const setNewNote = (note) => (
    {type: SET_NEW_NOTE, note}
);

export const newNoteLoading = () => (
    {type: NEW_NOTE_LOADING}
);

export const newNoteLoadingFailed = (err) => (
    {type: NEW_NOTE_LOADING_FAILED, err}
);


export const setFutureThreadSubject = (subject) => (
    {type: SET_FUTURE_THREAD_SUBJECT, subject}
);

export const setAddMessage = (message) => (
    {type: SET_ADD_MESSAGE, message}
);

export const addMessage = (message) => (
    {type: ADD_MESSAGE, message}
);

export const messageUploading = (uuid) => (
    {type: MESSAGE_UPLOADING, uuid}
);

export const messageUploaded = (uuid, message) => (
    {type: MESSAGE_UPLOADED, uuid, message}
);

export const messageUploadFail = (uuid) => (
    {type: MESSAGE_UPLOAD_FAIL, uuid}
);

export const attachmentUploaded = (uuid, id, uploaded) => (
    {type: ATTACHMENT_UPLOADED, uuid, id, uploaded}
);

export const attachmentUploading = (uuid, id) => (
    {type: ATTACHMENT_UPLOADING, uuid, id}
);

export const attachmentUploadFail = (uuid, id) => (
    {type: ATTACHMENT_UPLOAD_FAIL, uuid, id}
);

export const setCidla = (cidla) => (
    {type: SET_CIDLA, cidla}
);

export const setCluid = (cluid) => (
    {type: SET_CLUID, cluid}
);

export const setMessageCount = (count) => (
    {type: SET_MESSAGE_COUNT, count}
)

export const setClientDetailOpen = (open) => (
    {type: CLIENT_DETAIL_OPEN, open}
);

export const setClientLoaded = (clientData) => (
    {type: CLIENT_LOADED, clientData}
);

export const clientLoading = () => (
    {type: CLIENT_LOADING}
);

export const clientLoadingFailed = (err) => (
    {type: CLIENT_LOADING_FAILED, err}
);

export const setDocumentUploading = (uploading) => (
    {type: DOCUMENT_UPLOADING, uploading}
);

export const setDocumentUploadingError = (error) => (
    {type: DOCUMENT_UPLOADING_ERR, error}
);

export const setUsersPhotos = (username, photo) => (
    {type: SET_USERS_PHOTOS, username, photo}
);

export const updateHumanTask = (humanTask) => (
    {type: UPDATE_HUMAN_TASK, humanTask}
);

export const fireInitialLoading = (cidla) => {

    return (dispatch) => {
        dispatch(initialLoading());

        //0] get messenger module
        dispatch(fireLoadMitModuleDispatch(cidla)).then(() => {

            //0.5] load environment
            dispatch(fireLoadEnvironment());

            //1] load thread
            let p1 = dispatch(getThreadForCase(cidla)).then((data) => {
                return dispatch(loadPerson(data.cluid))
            });

            Promise.resolve(p1).then(() => {
                //2] load history (needs threadId!)
                let p2 = dispatch(getThreadHistory(cidla));
                Promise.resolve(p2).then(() => {
                    //3] load user
                    let p3 = dispatch(fireGetLoggedUser()).then((user) => {
                        return dispatch(getHumanTask(cidla)).then((humanTask) => {
                            if (isTaskInStatus(humanTask, TAKE_READY_STATES) && canSolve(humanTask, user) && (!humanTask.actualOwner || isActualOwner(humanTask, user))) {
                                //3] take humantask needs loaded history!
                                return dispatch(takeHumanTask(cidla));
                            }
                            return Promise.resolve();
                        })
                    });
                    //4] loading done
                    Promise.resolve(p3).then(() => {
                        dispatch(initialLoadingDone())
                    });
                });
            }).catch((err) => {
                dispatch(initialLoadingFailed(err))
            })
        });
    }
};

export const loadPerson = (cluid) => {
    return (dispatch) => {
        dispatch(clientLoading());
        return fetch(Resource.getMitApi() + "/client/" + cluid, {
            method: "GET"
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(clientData => {
                dispatch(setClientLoaded(clientData));
                return Promise.resolve(clientData);
            })
            .catch(err => {
                console.error(err);
                dispatch(clientLoadingFailed(err));
                dispatch(fireShowAlert("Nepodařilo se načíst klientská data", ALERT_WARNING, 10000));
                return Promise.reject(err);
            })
    }
}

export const doMessagePolling = (cidla) => {
    return (dispatch, getState) => {
        if (getState().authorization.adfsAccessRefreshingFailed) {
            return Promise.resolve();
        }
        const actualMessageCount = getState().messenger.messageCount ;
        const actualThreadDeletedAt = getState().messenger.thread?.deletedAt;
        const oldTaskId = getState().humanTask?.humanTask?.id;

        return fetch(Resource.getMitApi() + "/thread/" + cidla + "/digest")
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(response => {
                dispatch(setMessageCount(response.messagesCount)) && dispatch(updateHumanTask({
                    id: response.taskId,
                    taskStatus: response.taskStatus,
                    actualOwner: response.taskOwner
                }));
                
                // Message count is changed or deletedAt is changed, reload thread.
                if (response.messagesCount !== actualMessageCount || response.deletedAt !== actualThreadDeletedAt){
                    dispatch(getThreadForCase(cidla))
                    return Promise.resolve(response);
                } else {
                    return Promise.resolve(response);
                }

            }).then(response => {
                if(oldTaskId && oldTaskId !== response.taskId){
                    let humanTask = dispatch(takeHumanTask(cidla, true, true))
                    return Promise.resolve(humanTask);
                }
            })
            .catch((err) => {
                dispatch(fireShowAlert("Nepodařilo se získat klientskou část konverzace", ALERT_DANGER, 10000));
                console.log(err);
                return Promise.reject(err);
            })
    }
}


export const getThreadForCase = (cidla) => {
    return (dispatch) => {
        dispatch(threadLoading(false));
        return fetch(Resource.getMitApi() + "/thread/" + cidla, {
            method: "GET"
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(threadDetail => {
                dispatch(setCluid(threadDetail.cluid)) && dispatch(setThread(threadDetail));
                return Promise.resolve(threadDetail);
            })
            .catch(err => {
                console.error(err);
                dispatch(threadLoadingFailed(err));
                dispatch(fireShowAlert("Načtení osoby selhalo", ALERT_WARNING));
                return Promise.reject(err);
            })
    }
};

export const createThreadForCaseWithMessage = (cidla, subject, caseType, cluid, message) => {
    return (dispatch, getState) => {
        dispatch(threadLoading(false));
        const finalMsg = {subject: subject, cluid: cluid, caseType: caseType};
        const firstname = getState().user.identity.firstName;
        const surname = getState().user.identity.surname;
        const username = getState().user.identity.username;
        const bodyJSon = {
            threadSubjectDto: finalMsg,
            messageDto: createMessage(message, [], [], username, firstname, surname)
        }
        return fetch(Resource.getMitApi() + "/thread/" + cidla + "/creator", {
            method: "POST",
            headers: {
                'Content-Type': "application/json",
            },
            body: JSON.stringify(bodyJSon)
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(threadDetail => {
                dispatch(setThread(threadDetail));
                return Promise.resolve(threadDetail);
            })
            .catch(err => {
                dispatch(threadLoadingFailed(err));
                console.error(err);
                return Promise.reject(err);
            })
    }
};
export const compositeMessageSend = (message,threadSubjectDto, cidla) => {
    return (dispatch, getState) => {
        let localPromises = dispatch(sendLocalDocuments(message));
        //2.2] Espis documents after
        let espisPromises = dispatch(sendEspisDocuments(message));
        return Promise.all([...espisPromises, ...localPromises]).then(() => {
            const finalMSg={
                threadSubjectDto: threadSubjectDto,
                messageDto: getState().messenger.thread.messages.find(m => m.uuid === message.uuid)
            }
            dispatch(messageUploading(message.uuid));
            return fetch(Resource.getMitApi() + "/thread/" + cidla + "/creator", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json",
                },
                body: JSON.stringify(finalMSg)
            })
                .then(Resource.checkStatus)
                .then(response => response.json())
                .then(data => {
                    dispatch(setCluid(data.cluid));
                    dispatch(messageUploaded(message.uuid, data.messages[0]));
                    dispatch(setThread(data));
                    dispatch(setMessageCount(1));
                    //dispatch(messageUploaded(message.uuid, data));
                    return Promise.resolve(data);
                })
        })
    }
};


export const createThreadMessage = (msg, subject, caseType, cluid, filesLocal, filesEspis) => {
    return (dispatch, getState) => {
        const cidla = getState().messenger.cidla;
        const finalMsg = {subject: subject, cluid: cluid, caseType: caseType};
        const forename = getState().user.identity.firstName;
        const surname = getState().user.identity.surname;
        const username = getState().user.identity.username;
        const tmpMsg =  createMessage(msg, filesLocal, filesEspis, username, forename, surname)
        //1] Create message+attachments mirror with flags for sending/error
        dispatch(addMessage(tmpMsg));
        return dispatch(compositeMessageSend(tmpMsg,finalMsg, cidla));
    }
};

export const sendMessage = (msg, filesLocal, filesEspis) => {
    return (dispatch, getState) => {
        const cidla = getState().messenger.cidla;
        const forename = getState().user.identity.firstName;
        const surname = getState().user.identity.surname;
        const username = getState().user.identity.username;
        const tmpMsg = createMessage(msg, filesLocal, filesEspis, username, forename, surname);

        //1] Create message+attachments mirror with flags for sending/error
        dispatch(addMessage(tmpMsg));
        return dispatch(handleMessageSend(tmpMsg, cidla));
    }
};

export const createSendMessage = (msg, filesLocal, filesEspis) => {
    return (dispatch, getState) => {
        const forename = getState().user.identity.firstName;
        const surname = getState().user.identity.surname;
        const username = getState().user.identity.username;
        const tmpMsg = createMessage(msg, filesLocal, filesEspis, username, forename, surname);
        return tmpMsg;
    }
};

export const resendMessage = (message) => {
    return (dispatch, getState) => {
        const cidla = getState().messenger.cidla;
        return dispatch(handleMessageSend(message, cidla));
    }
};

export const handleMessageSend = (message, cidla) => {
    return (dispatch, getState) => {
        let localPromises = dispatch(sendLocalDocuments(message));
        //2.2] Espis documents after
        let espisPromises = dispatch(sendEspisDocuments(message));

        return Promise.all([...espisPromises, ...localPromises]).then(() => {
            //3] Send complete message to BE
            //3.1] Signal uploading
            dispatch(messageUploading(message.uuid));
            //3.2] Get ready to send message from store
            const finalMsg = getState().messenger.thread.messages.find(m => m.uuid === message.uuid);
            //3.3] first message ever?
            const isFirst = getState().messenger.thread.messages.length === 1;
            //3.4] first banker message ever?
            const isFirstBankerMessage = getState().messenger.thread.messages.reduce((total, m) => (m.username && m.username !== "system" ? total + 1 : total), 0) === 1;
            //3.5] Check data
            return fetch(createHandleMessageURL(cidla, isFirst, isFirstBankerMessage).toString(), {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(finalMsg)
            })
                .then(Resource.checkStatus)
                .then(response => response.json())
                .then(data => {
                    //DF-25339 pouze 1. zpráva bouchne rovnou digest
                    if (isFirst) {
                        dispatch(getThreadHistory(cidla));
                        dispatch(doMessagePolling(cidla));
                    }
                    dispatch(messageUploaded(message.uuid, data));
                }).catch(() => {
                    dispatch(messageUploadFail(message.uuid))
                })
        }).catch((err) => {
            console.log(err)
        })
    }
};

export const sendLocalDocuments = (tmpMsg) => {
    return (dispatch, getState) => {
        if (tmpMsg.attachments && Array.isArray(tmpMsg.attachments)) {
            let promises = [];
            let filteredAttachments = tmpMsg.attachments.filter(a => a.source === FILE_SOURCE_LOCAL && a.flag?.unsent);
            filteredAttachments.forEach(a => {
                let p = dispatch(sendLocalDocument(a, tmpMsg.uuid, getState().messenger.cidla));
                promises.push(p)
            });
            return promises;
        }

        return [];
    }
};

export const sendLocalDocument = (attachment, id, cidla) => {
    return (dispatch) => {
        dispatch(attachmentUploading(attachment.uuid, id));
        return fetch(Resource.getMitApi() + "/document/" + cidla + "/upload", {
            method: "POST",
            body: prepareFile(attachment.file)
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(uploaded => {
                dispatch(attachmentUploaded(attachment.uuid, id, uploaded));
                return Promise.resolve(uploaded);
            })
            .catch(err => {
                console.error(err);
                dispatch(attachmentUploadFail(attachment.uuid, id));
                return Promise.reject(err);
            })
    }
};

export const sendEspisDocuments = (tmpMsg) => {
    return (dispatch, getState) => {
        if (tmpMsg.attachments && Array.isArray(tmpMsg.attachments)) {
            let promises = [];
            let filteredAttachments = tmpMsg.attachments.filter(a => a.source === FILE_SOURCE_ESPIS && a?.flag?.unsent);
            filteredAttachments.forEach(a => {
                let p = dispatch(sendEspisDocument(a, tmpMsg.uuid, getState().messenger.cidla));
                promises.push(p);
            });
            return promises;
        }

        return [];
    }
};

export const sendEspisDocument = (attachment, id, cidla) => {
    return (dispatch) => {
        dispatch(attachmentUploading(attachment.uuid, id));
        return fetch(Resource.getMitApi() + "/document/" + cidla + "/verify/" + attachment.duid, {
            method: "GET",
            headers: {
                'Content-Type': "application/json",
                'Accept': 'application/json'
            }
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(uploaded => {
                dispatch(attachmentUploaded(attachment.uuid, id, uploaded));
                return Promise.resolve(uploaded);
            })
            .catch(err => {
                console.error(err);
                dispatch(attachmentUploadFail(attachment.uuid, id))
                return Promise.reject(err);
            })
    }
};

export const downloadDocument = (doc) => {
    return () => {
        if (doc?.file) {
            openUploadedDocument(doc.file, doc.filename ? doc.filename : doc.duid);
            return Promise.resolve();
        }

        return fetch(Resource.getMitApi() + "/document/" + doc.uuid, {
            method: "GET"
        })
            .then(Resource.checkStatus)
            .then(response => {
                response.blob().then(blob => {
                    openDownloadedDocument(blob, doc.filename ? doc.filename : doc.duid)
                });
                return Promise.resolve();
            })
            .catch(err => {
                console.error(err);
            })
    }
};

export const getThreadHistory = (cidla) => {
    return (dispatch, getState) => {
        if (!getState().messenger?.thread?.id) {
            return Promise.resolve();
        }

        dispatch(threadHistoryLoading());
        return fetch(Resource.getMitApi() + "/history/" + cidla, {
            method: "GET"
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(history => {
                dispatch(setThreadHistory(history));
                return Promise.resolve(history);
            })
            .catch(err => {
                console.error("Unable to load human task history");
                dispatch(threadHistoryLoadingFailed(err));
                dispatch(fireShowAlert("Nepodařilo se načíst historii požadavku", ALERT_WARNING, 10000));
                return Promise.reject(err);
            })
    }
};


export const addNote = (text) => {
    return (dispatch, getState) => {
        const cidla = getState().humanTask.humanTask.cidla;
        const username = getState().user.identity.username;
        const forename = getState().user.identity.firstName;
        const surname = getState().user.identity.surname;
        const createdAt = new Date();
        dispatch(newNoteLoading());
        return fetch(Resource.getMitApi() + "/history/" + cidla, {
            method: "PUT",
            headers: {
                'Content-Type': "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                note: text,
                username: username,
                forename: forename,
                surname: surname,
                createdAt: createdAt,
                actionType: "NOTE"
            })
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(history => {
                dispatch(fireShowAlert("Poznámka vytvořena", ALERT_SUCCESS));
                return dispatch(setNewNote(history));
            })
            .catch(err => {
                dispatch(fireShowAlert("Nepodařilo se vytvořit poznámku", ALERT_DANGER));
                console.error(err);
                return dispatch(newNoteLoadingFailed(err));
            })
    }
};

const prepareFile = (f) => {
    let formData = new FormData();
    formData.append("file", f);
    return formData;
};


export function openDownloadedDocument(blob, fileName, extension) {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName ? fileName + (extension ? extension : "") : 'dokument';
    a.click();
}

export function openUploadedDocument(file, fileName) {
    let url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName ? fileName : 'dokument';
    a.click();
}

const createHandleMessageURL = (cidla, isFirst, isFirstBankerMessage) => {
    let baseURL = new URL(Resource.getMitApi() + "/thread/" + cidla + "/message");
    if (isFirst) {
        baseURL.searchParams.set('start', 'true');
    }
    if (isFirstBankerMessage) {
        baseURL.searchParams.set('firstBankerMessage', 'true');
    }
    return baseURL;
};

const createMessage = (msg, filesLocal, filesEspis, username, forename, surname) => {
    return {
        uuid: generateUUID(),
        body: msg,
        attachments: createAttachments(filesLocal, filesEspis),
        flag: createFlag(),
        createdAt: new Date(),
        username: username,
        forename: forename,
        surname: surname,
        readAt: null
    }
};

const createFlag = (error = false, sending = false) => {
    return {
        error: error,
        sending: sending,
        unsent: true
    }
};

const createAttachments = (filesLocal, filesEspis) => {
    let localAttachments = filesLocal.map(f => {
        return {
            uuid: generateUUID(),
            duid: '',
            componentId: '',
            filename: f.name,
            size: f.size,
            source: FILE_SOURCE_LOCAL,
            flag: createFlag(),
            file: f
        }
    });

    let espisAttachments = filesEspis.map(e => {
        return {
            uuid: generateUUID(),
            duid: e,
            componentId: '',
            filename: e,
            size: 0,
            source: FILE_SOURCE_ESPIS,
            flag: createFlag()
        }
    });

    return [...localAttachments, ...espisAttachments]
};
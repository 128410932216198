import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { evaluateUserRoles } from "../../actions/userActions";

const UserRoleEvaluator = () => {

    const dispatch = useDispatch();
    const username = useSelector(state => state.user?.identity?.username || ""); // Needed for use effect: otherwise I would listen on same object, that I update.
    const user = useSelector(state => state.user?.identity || {});
    const humanTask = useSelector(state => state.humanTask?.humanTask || "");

    const messageCount = useSelector(state => state?.messenger?.messageCount);

    useEffect(() => {
        if(username){
            dispatch(evaluateUserRoles(user, humanTask, messageCount));
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, username, humanTask, messageCount]);

    return null;
}

export default UserRoleEvaluator;
import { env } from "../configuration/config";

class Resource {

    getEnv() {
        return env.config.enviroment;
    }

    getWebApiUrl() {
        return env.config.webApiUrl;
    }

    getWebApiKey() {
        return env.config.webApiKey;
    }    

    getAdfsUrl() {
        return env.config.adfsUrl;
    }    

    /**
     * Auth api is exposed on mit module only (mit-gbiz doesn't expose this api)
     */
    getMitAuthApi() {
        return this.getWebApiUrl() + "/smart-mit";
    }

    /**
     * Dispatch api exposed on mitfed module returns specific messenger module (for given cidla). This module's api will be called using getMitApi() as base address.
     */
    getMitDispatchApi() {
        return this.getWebApiUrl() + "/smart-mitfed";
    }

    getMitApi() {
        return this.getWebApiUrl() + this.getMitModuleWebApiPath();
    }

    getMitModuleWebApiPath() {
        return this.mitModule === 'mit-gbiz' ? '/smart-mit-gbiz' : '/smart-mit';
    }

    getMitModulePath() {
        return this.mitModule === 'mit-gbiz' ? '/mit-gbiz/api' : '/mit/api';
    }

    getProcessVersions() {
        return env.config.processVersions;
    }

    checkStatus(response) {
        if(response.status >= 200 && response.status < 300) {
            return response;
        } else {
            throw new Error(response);
        }
    }

    setMitModule(mitModule) {
        this.mitModule = mitModule;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Resource();

/**
 * Invisible component taking care of scrolling to bottom of chat.
 */

import React, { useRef, useEffect } from 'react';

 const MessageFloor = (props) => {
    const divRef = useRef(null);

    useEffect(() => {
        if(props.last && document.readyState){
            let canvas = document.getElementById("threadCanvasSection");
            canvas.scrollTop = canvas.scrollHeight;
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.last, document.readyState, props.height]);

    
      return <div id="chat-floor" ref={divRef}></div>;
 }

 export default MessageFloor;
import {
    HUMAN_TASK_LOADED,
    ACTUAL_OWNER_FULLNAME,
    ALERT_WARNING,
    ALERT_DANGER,
    HUMAN_TASK_LOADING,
    HUMAN_TASK_LOADING_FAILED,
    HUMAN_TASK_ACTION, ALERT_SUCCESS
} from "../core/const";
import Resource from "../core/serverResource"
import {fireShowAlert} from "./alertActions";
import {getThreadHistory} from "./messengerActions";

export const humanTaskLoading = (humanTask) => (
    {type: HUMAN_TASK_LOADING, humanTask}
);

export const humanTaskLoadingFailed = (humanTask) => (
    {type: HUMAN_TASK_LOADING_FAILED, humanTask}
);

export const setHumanTask = (humanTask) => (
    {type: HUMAN_TASK_LOADED, humanTask}
);

export const humanTaskAction = (action, progress, error) => (
    {type: HUMAN_TASK_ACTION, action, progress, error}
);

export const getHumanTask = (cidla) => {
    return (dispatch) => {
        dispatch(humanTaskLoading());
        return fetch(Resource.getMitApi() + "/humantask/" + cidla, {
            method: "GET"
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(humanTask => {
                dispatch(setHumanTask(humanTask));
                return Promise.resolve(humanTask);
            })
            .catch(err => {
                dispatch(humanTaskLoadingFailed());
                dispatch(fireShowAlert("Nepodařilo se získat úkol", ALERT_DANGER, 10000));
                console.error("Unable to load human task");
                console.error(err);
                return Promise.reject();
            })
    }
};

export const takeHumanTask = (cidla, withHistory = false, noaction = false) => {
    return (dispatch, getState) => {
        let url = Resource.getMitApi() + "/humantask/" + cidla;
        let alreadyTaken = getState().messenger.history.some(h => h.actionType === 'TAKE');
        if(noaction || alreadyTaken){
            url += "?status=noaction"
        }

        dispatch(humanTaskLoading());
        return fetch(url, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify({})
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(humanTask => {
                dispatch(setHumanTask(humanTask));
                dispatch(clearTaskExpiration(humanTask, cidla));
                withHistory && dispatch(getThreadHistory(cidla));
                return Promise.resolve(humanTask);
            })
            .catch(err => {
                dispatch(humanTaskLoadingFailed());
                dispatch(fireShowAlert("Nepodařilo se získat úkol", ALERT_DANGER, 10000));
                console.error("Unable to load human task");
                console.error(err);
                return Promise.reject();
            })
    }
};

export const setActualOwnerFullName = (actualOwnerFullName) => (
    {type: ACTUAL_OWNER_FULLNAME, actualOwnerFullName}
);

export const getUserFullName = (username, isActualOwner = true) => {
    return (dispatch) => {
        return fetch(Resource.getMitApi() + "/user/" + username, {
            method: "GET"
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(user => {
                isActualOwner && dispatch(setActualOwnerFullName(user.fullName));
                return Promise.resolve(user);
            })
            .catch(err => {
                dispatch(fireShowAlert(isActualOwner ? "Nepodařilo se načíst jméno vlastníka úkolu" : "Nepodařilo se načíst jméno bankéře", ALERT_WARNING, 10000));
                console.error("Unable to load" + (isActualOwner ? "actual owner fullname" : "bankers fullname"));
                return Promise.reject(err);
            })
    }
};

export const clearTaskExpiration = (humanTask, cidla) => {
    return (dispatch) => {
        if(!(humanTask.expirationDate || humanTask.firstEscalationSent)){
            return Promise.resolve();
        }

        let now = new Date().getTime();
        let bodyData = {"firstOpenedByBankerDate": now};

        return fetch(Resource.getMitApi() + "/humantask/" + cidla + "/clearExpiration", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(bodyData)
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(humanTask => {
                return dispatch(setHumanTask(humanTask));
            })
            .catch(err => {
                dispatch(fireShowAlert("Nepodařilo se zrušit expiraci na úkolu", ALERT_WARNING, 10000));
                console.error("Unable to clear task expiration and escalations");
                console.error(err);
            })
    }
};

export function fireComplete(cidla, bodyData) {
    return (dispatch) => {

        dispatch(humanTaskAction('completing', true, false));

        if (bodyData === undefined) {
            bodyData="{}";
        }

        return fetch(Resource.getMitApi() + "/humantask/" + cidla + "/complete", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(bodyData)
        })
            .then(response => response.json())
            .then(humanTask => {
                dispatch(setHumanTask(humanTask));
                dispatch(humanTaskAction('completing', false, false));
                dispatch(fireShowAlert("Konverzace byla vyřešena.", ALERT_SUCCESS));
                dispatch(getThreadHistory(cidla));
                return Promise.resolve(humanTask)
            })
            .catch(err => {
                dispatch(fireShowAlert("Konverzaci se nepodařilo uzavřít.", ALERT_DANGER));
                dispatch(humanTaskAction('completing', false, true));
                return Promise.reject(err)
            });
    }
}

export function fireDelegate(cidla, bodyData) {
    return (dispatch) => {
        dispatch(humanTaskAction('delegating', true, false));
        return fetch(Resource.getMitApi() + "/humantask/" + cidla + "/delegate", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(bodyData)
        })
            .then(response => response.json())
            .then(humanTask => {
                dispatch(humanTaskAction('delegating', false, false));
                dispatch(fireShowAlert("Konverzace byla nadelegována.", ALERT_SUCCESS, 10000));
                dispatch(getThreadHistory(cidla));
                dispatch(setHumanTask(humanTask));
                return Promise.resolve(humanTask)
            })
            .catch(err => {
                dispatch(fireShowAlert("Konverzaci se nepodařilo nadelegovat.", ALERT_DANGER, 10000));
                dispatch(humanTaskAction('delegating', false, true));
                return Promise.reject(err)
            });
    }
}

export function fireDelegateCase(cidla) {
    return (dispatch) => {
        dispatch(humanTaskAction('delegating', true, false));
        return fetch(Resource.getMitApi() + "/humantask/" + cidla + "/delegateCase", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST"
        })
            .then(response => response.json())
            .then(humanTask => {
                dispatch(humanTaskAction('delegating', false, false));
                dispatch(fireShowAlert("Konverzace byla nadelegována.", ALERT_SUCCESS, 10000));
                dispatch(getThreadHistory(cidla));
                dispatch(setHumanTask(humanTask));
                return Promise.resolve(humanTask)
            })
            .catch(err => {
                dispatch(fireShowAlert("Konverzaci se nepodařilo nadelegovat.", ALERT_DANGER, 10000));
                dispatch(humanTaskAction('delegating', false, true));
                return Promise.reject(err)
            });
    }
}

export function fireClaimAndTake(cidla, noaction){
    return (dispatch) => {
        let p1 = dispatch(fireClaim(cidla, false));
        return Promise.resolve(p1).then(() => {
            dispatch(takeHumanTask(cidla, true, noaction));
        });
    }
}

export function fireClaim(cidla, withoutTake = true) {
    return (dispatch) => {
        dispatch(humanTaskAction('claiming', true, false));
        return fetch(Resource.getMitApi() + "/humantask/" + cidla + "/claim", {
            headers: {
                "Content-Type": "application/json"
            },
            method: "PUT"
        })
            .then(response => response.json())
            .then(humanTask => {
                dispatch(setHumanTask(humanTask));
                withoutTake && dispatch(clearTaskExpiration(humanTask, cidla));
                dispatch(humanTaskAction('claiming', false, false));
                withoutTake && dispatch(getThreadHistory(cidla));
                dispatch(fireShowAlert("Řešení konverzace převzato", ALERT_SUCCESS, 10000));
                return Promise.resolve(humanTask)
            })
            .catch(err => {
                dispatch(humanTaskAction('claiming', false, true));
                dispatch(fireShowAlert("Konverzaci se nepodařilo převzít", ALERT_DANGER, 10000));
                return Promise.resolve()
            });
    }
}

export function fireComplain(cidla) {
    return (dispatch) => {
        dispatch(humanTaskAction('complaining', true, false));
        return fetch(Resource.getMitApi() + "/humantask/" + cidla + "/complain", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify({})
        })
            .then(response => response.json())
            .then(humanTask => {
                dispatch(humanTaskAction('complaining', false, false));
                dispatch(fireShowAlert("Konverzace obnovena", ALERT_SUCCESS, 10000));
                dispatch(getThreadHistory(cidla));
                dispatch(setHumanTask(humanTask));
                return dispatch(takeHumanTask(cidla, false, true));
            })
            .catch(err => {
                dispatch(fireShowAlert("Nepodařilo se obnovit konverzaci", ALERT_DANGER, 10000));
                dispatch(humanTaskAction('complaining', false, true));
                return Promise.reject(err)
            });
    }
}


export function fireRestore() {
    return (dispatch, getState) => {
        dispatch(humanTaskAction('restoring', true, false));
        return fetch(Resource.getMitApi() + "/case/" + getState().messenger?.client?.cluid, {
            method: "POST"
        })
            .then(response => response.json())
            .then(applicationUrl => {
                dispatch(humanTaskAction('restoring', false, false));
                dispatch(fireShowAlert("Konverzace obnovena", ALERT_SUCCESS, 10000));
                window.location.replace(applicationUrl.value);
                //todo redirect
                return Promise.resolve(applicationUrl.value)
            })
            .catch(err => {
                dispatch(fireShowAlert("Nepodařilo se obnovit konverzaci", ALERT_DANGER, 10000));
                dispatch(humanTaskAction('restoring', false, true));
                return Promise.reject(err)
            });
    }
}

export function fireCancel() {
    return (dispatch, getState) => {
        dispatch(humanTaskAction('cancelling', true, false));
        return fetch(Resource.getMitApi() + "/humantask/" + getState().messenger?.cidla + "/cancel", {
            method: "DELETE"
        })
            .then(response => response.json())
            .then(() => {
                dispatch(humanTaskAction('cancelling', false, false));
                dispatch(fireShowAlert("Konverzace zrušena", ALERT_SUCCESS, 10000));
                window.location.reload();
                return Promise.resolve()
            })
            .catch(err => {
                dispatch(fireShowAlert("Nepodařilo se zrušit konverzaci", ALERT_DANGER, 10000));
                dispatch(humanTaskAction('cancelling', false, true));
                return Promise.reject(err)
            });
    }
}
